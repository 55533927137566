import { reactive } from 'vue';

export const sessionData = reactive({
  isLoggedIn: false,
  userId: null,
  firstName: '',
  surname: '', // Changed to match the login form
  fullName: '',
  onBoarding: false,

  // case
  hasSubmittedCase: null,

  caseId: ''
});

export async function destroySession() {
  try {
    const response = await fetch('https://api.codfalseban.com/v1/auth/user/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const result = await response.json();

    if (result.status === 'success') {
      // Clear session data
      sessionData.isLoggedIn = false;
      sessionData.userId = null;
      sessionData.firstName = '';
      sessionData.surname = ''; // Changed to match the session data
      sessionData.fullName = '';
      sessionData.onBoarding = false;
      sessionData.hasSubmittedCase = null;
      sessionData.caseId = '';
    } else {
      console.error('Logout failed:', result.message);
    }
  } catch (error) {
    console.error('Error during logout:', error);
  }
}
