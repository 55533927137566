<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Request help</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Get the help you need to challenge a false ban.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5" style="    margin-top: -121px;">
                <!-- 121 px instead of -97 px because g-4 represents 24 px -->
                <div class="container text-center">
                    <div class="row row-cols-1 row-cols-lg-3 g-4 justify-content-center">
                        <div class="col d-flex">
                            <div class="card lg-card-blue h-100 d-flex flex-column justify-content-between"
                                style="padding: 25px 0px; border-radius: 25px;">
                                <div class="card-body align-items-center text-center">
                                    <img src="https://cdn.pcforge.nl/assets/1.0/images/ondemand.png" width="64">
                                    <p></p>
                                    <h3 class="card-title">Standard</h3>
                                    <h6 class="card-subtitle mb-2"
                                        style="color: white; font-size: 25px; font-weight: bold;"> Completely free
                                    </h6>
                                    <span>Access Call of Duty false ban support, providing guides and tips to help you
                                        understand your rights and appeal the ban effectively.</span>

                                </div>
                            </div>
                        </div>
                        <div class="col d-flex">
                            <div class="card lg-card-orange h-100 d-flex flex-column justify-content-between"
                                style="padding: 25px 0px; border-radius: 25px;">
                                <div class="card-body align-items-center text-center">
                                    <img src="https://cdn.pcforge.nl/assets/1.0/images/basis.png" width="64">
                                    <p></p>
                                    <h3 class="card-title">Assistance</h3>
                                    <h6 class="card-subtitle mb-2"
                                        style="color: white; font-size: 25px; font-weight: bold;"> €9,99 / one-off </h6>
                                    <span>Utilize Call of Duty false ban support, which includes a formal challenge
                                        process along with guides and tips to assert your rights effectively.</span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-lg-3 g-4 justify-content-center mt-5">

                        <div class="table-responsive">
                            <table class="table table-striped text-successtable-border border-light">
                                <thead class="border-light">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"><strong>Standard</strong></th>
                                        <th scope="col"><strong>Assistance</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row" class="fw-normal">Call of Duty False Ban Support</th>
                                        <td>✅</td>
                                        <td>✅</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="fw-normal">Guides and Tips for Appealing Bans</th>
                                        <td>✅</td>
                                        <td>✅</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="fw-normal">Formal Challenge </th>
                                        <td>❌</td>
                                        <td>✅</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="fw-normal">Personalized Assistance</th>
                                        <td>❌</td>
                                        <td>✅</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <br>
                <br>
                <div class="row gx-5 align-items-center text-center">
                    <h2 class="display-6 lh-1" style="font-weight: bold;">Frequently Asked Questions</h2>
                    <span class="lead fw-normal text-muted mb-5 mb-lg-4"> If you don't see an answer to your question,
                        you can
                        contact us via our <router-link to="/contact">contact form</router-link>.</span>
                    <br>
                    <br>
                    <div class="col-md-7 mx-auto">
                        <div id="accordionExample" class="accordion">
                            <div class="accordion-item">
                                <h2 id="headingOne" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne" class="accordion-button">
                                        <b>Why does the assistance cost money?</b>
                                    </button>
                                </h2>
                                <div id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse show">
                                    <div class="accordion-body">The assistance fee is solely to cover the actual costs
                                        we incur during the formal appeal process, like postage and printing. We have no
                                        intention of making a profit from this, which is reflected in the low fee.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingTwo" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                        aria-expanded="false" aria-controls="collapseTwo"
                                        class="accordion-button collapsed">
                                        <b>What types of false bans do you assist with?</b>
                                    </button>
                                </h2>
                                <div id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse">
                                    <div class="accordion-body">We assist with Call of Duty false bans that fall
                                        under Activision's Security and Enforcement policy, particularly those related
                                        to accusations of cheating (using unauthorized software or exploits) and hacking
                                        (modifying game code or data). If you've been falsely accused and banned, we're
                                        here to help.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingFour" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                        aria-expanded="false" aria-controls="collapseThree"
                                        class="accordion-button collapsed">
                                        <b>What if I've already tried appealing my ban unsuccessfully?</b>
                                    </button>
                                </h2>
                                <div id="collapseThree" aria-labelledby="headingFour" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse">
                                    <div class="accordion-body">Our service focuses on formal appeals directly to
                                        Activision's legal team, as regular player support and appeal teams generally
                                        don't adhere to the general applicable jurisdiction aimed at protecting
                                        consumers from practices such as the suspension of accounts.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingThree" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                        aria-expanded="false" aria-controls="collapseFour"
                                        class="accordion-button collapsed">
                                        <b>What if I'm located outside of the jurisdiction you typically operate in?</b>
                                    </button>
                                </h2>
                                <div id="collapseFour" aria-labelledby="headingThree" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse">
                                    <div class="accordion-body">Unfortunately, we currently assist players
                                        within the European Union (EU) due to relevant EU laws
                                        and regulations. If you are located outside of the EU, we are unable to provide
                                        direct assistance with your appeal at this time.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingThree" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                        aria-expanded="false" aria-controls="collapseFive"
                                        class="accordion-button collapsed">
                                        <b>My ban was correct, can you still help me?</b>
                                    </button>
                                </h2>
                                <div id="collapseFive" aria-labelledby="headingThree" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse">
                                    <div class="accordion-body">No, our service is designed to assist
                                        players who have been falsely banned. We assist in cases where there's a
                                        genuine error or misunderstanding, not in overturning legitimate bans. If you
                                        believe your ban was justified, we unfortunately cannot provide assistance in
                                        challenging it.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingThree" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix"
                                        aria-expanded="false" aria-controls="collapseSix"
                                        class="accordion-button collapsed">
                                        <b>Do you offer any guarantees regarding the success of my appeal?</b>
                                    </button>
                                </h2>
                                <div id="collapseSix" aria-labelledby="headingThree" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse">
                                    <div class="accordion-body">
                                        Unfortunately, we cannot offer any guarantees regarding the outcome of your
                                        appeal. The final decision rests with Activision, and while we strive to provide
                                        the best possible representation and maximize your chances of success, we cannot
                                        control their decision-making process.
                                        <br><br>
                                        Regardless, we'll explore further options, such as guiding you through the
                                        process of bringing the issue to local consumer protection or data protection
                                        authorities.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
    components: {
        AppHeader
    }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>