<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Our approach</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Personalized help for players dealing with wrongful bans in Call of Duty.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5">
                <div class="row justify-content-center">
                    <div class="card" style="border: unset; background: unset">
                        <div class="card-body d-flex justify-content-center align-items-center mt-3">
                            <div class="col-lg-8 mx-auto">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <img src="https://cdn.codfalseban.com/assets/images/steps-we-take.png" width="128"
                                            alt="Image">
                                    </div>
                                    <div class="col-lg-8">
                                        <h2 class="fw-bold">The steps we take</h2>
                                        <p>Our approach to challenging CoD false bans involves a systematic and
                                            dedicated process aimed at achieving the best
                                            possible outcome for the people that ask our help.</p>
                                    </div>
                                    <div class="card mt-5" style="    border: 2px solid #f3f4f6;">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start mt-3">
                                                <div
                                                    class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                                    <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">1</span>
                                                </div>
                                                <div class="ps-5">
                                                    <h4 class="fw-bold">Case assessment</h4>
                                                    <p>We start by thoroughly reviewing the details of your ban,
                                                        including any communication you've had with Activision, evidence
                                                        you may have, and the specific circumstances surrounding the
                                                        ban.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 class="text-center mt-5"><i class="bi bi-arrow-down-circle"></i></h1>

                                    <div class="card mt-5" style="    border: 2px solid #f3f4f6;">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start mt-3">
                                                <div
                                                    class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                                    <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">2</span>
                                                </div>
                                                <div class="ps-5">
                                                    <h4 class="fw-bold">Regulatory review</h4>
                                                    <p>We analyze your case in light of relevant data protection and
                                                        consumer rights laws, such as GDPR and UCPD. We identify any
                                                        potential
                                                        legal grounds for challenging your ban.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="alert alert-dismissible alert-warning mt-5">
                                        <strong><i class="fa-solid fa-circle-exclamation"></i> Our service is only
                                            available in the European Union
                                            (EU)</strong><br> Due to the specific legal frameworks we employ to contest
                                        bans which are primarily
                                        applicable within the EU jurisdiction, we can only help you if you are an EU
                                        resident.
                                    </div>

                                    <h1 class="text-center mt-5"><i class="bi bi-arrow-down-circle"></i></h1>
                                    <div class="card mt-5" style="    border: 2px solid #f3f4f6;">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start mt-3">
                                                <div
                                                    class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                                    <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">3</span>
                                                </div>
                                                <div class="ps-5">
                                                    <h4 class="fw-bold">Communication and preparation</h4>
                                                    <p>We maintain open communication with you throughout the process,
                                                        keeping you informed of our progress and any actions
                                                        you need to take. We'll work with you to gather any additional
                                                        evidence or information that might strengthen your case.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 class="text-center mt-5"><i class="bi bi-arrow-down-circle"></i></h1>

                                    <div class="card mt-5" style="    border: 2px solid #f3f4f6;">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start mt-3">
                                                <div
                                                    class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                                    <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">4</span>
                                                </div>
                                                <div class="ps-5">
                                                    <h4 class="fw-bold">Formal challenge</h4>
                                                    <p>We prepare and submit a formal challenge to Activision,
                                                        presenting a compelling legal argument supported by evidence.
                                                        We leverage specific legal frameworks to advocate for your
                                                        rights and seek a reversal of your ban.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="alert alert-dismissible alert-info mt-5">
                                        <strong><i class="fa-solid fa-clock"></i> The process will take time
                                        </strong><br>The formal challenge we submit requires thorough review by
                                        Activision. This process takes time.
                                        We'll continue to provide updates throughout the process.
                                    </div>

                                    <h1 class="text-center mt-5"><i class="bi bi-arrow-down-circle"></i></h1>
                                    
                                    <div class="card mt-5" style="    border: 2px solid #f3f4f6;">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start mt-3">
                                                <div
                                                    class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                                    <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">5</span>
                                                </div>
                                                <div class="ps-5">
                                                    <h4 class="fw-bold">Follow up</h4>
                                                    <p>We monitor the progress of your challenge and follow up with
                                                        Activision as needed. If the initial challenge is unsuccessful,
                                                        we explore further options, including escalation to higher
                                                        authorities or alternative dispute resolution mechanisms.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="alert alert-dismissible alert-warning mt-5">
                                        <strong><i class="fa-solid fa-circle-exclamation"></i> We can't influence Activision's decision</strong><br>
                                        We advocate strongly on your behalf, but the final decision to unban your
                                        account rests solely with Activision. Even if we believe our arguments are sound
                                        and well-supported, we cannot compel Activision to take any specific action.
                                        We will do our best to support you in any further steps. As a collective we are stronger.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </div>
</template>
<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
    components: {
        AppHeader
    },
    data() {
        // your data here
    }
}
</script>


<style scoped>
/* Add any component-specific styles here */
.icon-md {
    width: 2.6rem;
    height: 2.6rem;
    line-height: 2.6rem;
    text-align: center;
    font-size: 0.8rem;
}

.bg-approach {
    --bs-bg-opacity: 1;
    background-color: rgb(9 21 184 / 14%) !important;
}
</style>