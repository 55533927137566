<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Submit case</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Complete the following information to submit your case.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5">
                <div class="row justify-content-center">
                    <div class="col-lg-3 mb-4">
                        <!-- Include Account Navigation -->
                        <AccountNavigation :currentPath="currentPath" />
                    </div>
                    <div class="col-lg-7">
                        <!-- Form Progress Steps -->
                        <div class="progress-container mb-5">
                            <div :class="['progress-step', { active: currentStep >= 1 }]">1</div>
                            <div :class="['progress-bar', { active: currentStep >= 2 }]"></div>
                            <div :class="['progress-step', { active: currentStep >= 2 }]" ref="step2">2</div>
                            <div :class="['progress-bar', { active: currentStep >= 3 }]"></div>
                            <div :class="['progress-step', { active: currentStep >= 3 }]">3</div>
                        </div>

                        <!-- Step 1 Form -->
                        <div v-if="currentStep === 1">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Platform information</h4>
                                    <h6 class="card-subtitle mb-2 text-muted">We need to know what platform you play on
                                        and whether any unknown accounts are linked
                                        to your account.</h6>
                                    <form class="mt-4">
                                        <div class="mb-3">
                                            <label for="platform" class="form-label fw-bold">Platform</label>
                                            <select id="platform" class="form-control" v-model="formData.platform"
                                                required>
                                                <option value="" disabled>Select your platform</option>
                                                <option value="pc">PC</option>
                                                <option value="playstation">PlayStation</option>
                                                <option value="xbox">Xbox</option>
                                            </select>
                                        </div>

                                        <div class="mb-3">
                                            <label class="form-label fw-bold">Is another unknown account
                                                linked?</label>
                                            <div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio"
                                                        id="otherAccountLinkedYes" value="yes"
                                                        v-model="formData.otherAccountLinked" required>
                                                    <label class="form-check-label"
                                                        for="otherAccountLinkedYes">Yes</label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio"
                                                        id="otherAccountLinkedNo" value="no"
                                                        v-model="formData.otherAccountLinked" required>
                                                    <label class="form-check-label"
                                                        for="otherAccountLinkedNo">No</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-3" v-if="formData.otherAccountLinked === 'yes'">
                                            <label for="otherAccountLinkedPlatform" class="form-label fw-bold">Linked
                                                Account Platform</label>
                                            <input type="text" id="otherAccountLinkedPlatform" class="form-control"
                                                placeholder="Enter the platform of the linked account"
                                                v-model="formData.otherAccountLinkedPlatform" required>
                                        </div>

                                        <div v-if="formError" class="alert alert-danger" role="alert">
                                            <b><i class="fa-solid fa-circle-exclamation"></i> The following error has
                                                occurred:</b>
                                            <ul style="margin: 0; padding: 0 0 0 20px; list-style:none;">
                                                <li>{{ formError }}</li>
                                            </ul>
                                        </div>

                                        <button type="button" class="btn btn-success" @click="nextStep">
                                            <i class="fa-solid fa-arrow-right-to-bracket"></i> Next step
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- Step 2 Form -->
                        <div v-if="currentStep === 2">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Additional information</h4>
                                    <h6 class="card-subtitle mb-2 text-muted">We need some additional information
                                        related to your ban, such as whether any third-party software was used.</h6>
                                    <form class="mt-4">
                                        <div class="mb-3">
                                            <label for="thirdPartySoftware" class="form-label fw-bold">Have you used any
                                                third-party software?</label>
                                            <select id="thirdPartySoftware" class="form-control"
                                                v-model="formData.thirdPartySoftware" required>
                                                <option value="" disabled>Select an option</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>

                                        <div class="mb-3">
                                            <label for="description" class="form-label fw-bold">Description</label>
                                            <textarea id="description" class="form-control"
                                                v-model="formData.description" rows="4"
                                                placeholder="Provide any additional details here. Also add any third-party software you have used."
                                                required></textarea>
                                        </div>

                                        <div v-if="formError" class="alert alert-danger" role="alert">
                                            <b><i class="fa-solid fa-circle-exclamation"></i> The following error has
                                                occurred:</b>
                                            <ul style="margin: 0; padding: 0 0 0 20px; list-style:none;">
                                                <li v-if="formError === 'missingFields'">Please fill in all the required
                                                    fields.</li>
                                                <li v-if="formError === 'missingDescription'">Please provide a
                                                    description.</li>
                                            </ul>
                                        </div>

                                        <button type="button" class="btn btn-light" @click="previousStep">
                                            <i class="fa-solid fa-arrow-left"></i> Previous step
                                        </button>
                                        <button style="float: right;" type="button" class="btn btn-success"
                                            @click="nextStep">
                                            <i class="fa-solid fa-arrow-right-to-bracket"></i> Next step
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- Success Message -->
                        <div v-if="currentStep === 3">
                            <div class="alert alert-dismissible alert-success">
                                <strong><i class="fa-solid fa-circle-check"></i> Case submitted successfully.</strong>
                                <br>
                                You can now
                                <router-link to="/account/overview">
                                    <span class="badge rounded-pill bg-light" style="color:black;">
                                        <i class="fa-solid fa-arrow-right-to-bracket"></i> view your case
                                    </span>
                                </router-link>
                                and monitor the status of your case. We'll let you know the next steps.
                            </div>

                            <div class="card mt-5" style="border: 2px solid #f3f4f6;">
                                <div class="card-body">
                                    <div class="d-flex align-items-start mt-3">
                                        <div class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                            <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">1</span>
                                        </div>
                                        <div class="ps-5">
                                            <h4 class="fw-bold">Initial review</h4>
                                            <p>We will assess the information you provided, including any evidence
                                                or documentation related to your ban. This helps us understand the
                                                context and grounds for your challenge.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h1 class="text-center mt-5"><i class="bi bi-arrow-down-circle"></i></h1>


                            <div class="card mt-5" style="border: 2px solid #f3f4f6;">
                                <div class="card-body">
                                    <div class="d-flex align-items-start mt-3">
                                        <div class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                            <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">2</span>
                                        </div>
                                        <div class="ps-5">
                                            <h4 class="fw-bold">Investigation</h4>
                                            <p>We will investigate whether there is a chance that we can challenge the
                                                ban, which may involve reaching out to relevant parties and gathering
                                                additional evidence to support your case.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h1 class="text-center mt-5"><i class="bi bi-arrow-down-circle"></i></h1>


                            <div class="card mt-5" style="border: 2px solid #f3f4f6;">
                                <div class="card-body">
                                    <div class="d-flex align-items-start mt-3">
                                        <div class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                            <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">3</span>
                                        </div>
                                        <div class="ps-5">
                                            <h4 class="fw-bold">Legal challenge</h4>
                                            <p>If we determine that there are valid grounds to contest your ban, we will
                                                send a formal letter on your behalf to Activision's legal team.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-dismissible alert-warning mt-5">
                                <strong><i class="fa-solid fa-clock"></i> The process will take time
                                </strong><br>The formal challenge we submit requires thorough review by
                                Activision. This process takes time.
                                We'll continue to provide updates throughout the process.
                            </div>

                            <h1 class="text-center mt-5"><i class="bi bi-arrow-down-circle"></i></h1>


                            <div class="card mt-5" style="border: 2px solid #f3f4f6;">
                                <div class="card-body">
                                    <div class="d-flex align-items-start mt-3">
                                        <div class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                            <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">4</span>
                                        </div>
                                        <div class="ps-5">
                                            <h4 class="fw-bold">Updates</h4>
                                            <p>Throughout the process, we will keep you informed of any significant
                                                developments. You can also check your account for real-time updates on
                                                the status of your case.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h1 class="text-center mt-5"><i class="bi bi-arrow-down-circle"></i></h1>


                            <div class="card mt-5" style="border: 2px solid #f3f4f6;">
                                <div class="card-body">
                                    <div class="d-flex align-items-start mt-3">
                                        <div class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                            <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">5</span>
                                        </div>
                                        <div class="ps-5">
                                            <h4 class="fw-bold">Resolution</h4>
                                            <p>Once we have completed our investigation and submitted our challenge, we
                                                will await a response from the relevant parties. We will notify you as
                                                soon as we receive any updates regarding the outcome of your case.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h1 class="text-center mt-5"><i class="bi bi-arrow-down-circle"></i></h1>


                            <div class="card mt-5" style="border: 2px solid #f3f4f6;">
                                <div class="card-body">
                                    <div class="d-flex align-items-start mt-3">
                                        <div class="icon-md flex-shrink-0 text-white bg-approach rounded-circle me-3">
                                            <span class="fw-bold" style="font-size:22px;color:#3d0ea9;">6</span>
                                        </div>
                                        <div class="ps-5">
                                            <h4 class="fw-bold">Next steps</h4>
                                            <p>Depending on the response, we will advise you on any further actions that
                                                may be necessary, including contacting the relevant authorities or
                                                pursuing additional legal steps.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';
import AccountNavigation from '@/components/account/nav/AccountNavigation.vue';
import { sessionData } from '@/session';

export default {
    components: {
        AppHeader,
        AccountNavigation
    },
    setup() {
        const currentPath = 'account/case'; // Set the current path for the navigation

        return {
            sessionData,
            currentPath
        };
    },
    data() {
        return {
            currentStep: 1,
            confettiTriggered: false,
            formData: {
                // Step 1 - Platform Information
                platform: '',
                otherAccountLinked: '',
                otherAccountLinkedPlatform: '',
                // Step 2 - Additional Information
                thirdPartySoftware: '',
                description: ''
            },
            formError: null // Use null to indicate no error initially
        };
    },
    methods: {
        async nextStep() {
            if (this.currentStep === 1) {
                if (this.isStep1Valid()) {
                    this.currentStep = 2;
                    this.formError = null;
                } else {
                    this.formError = this.getStep1Error();
                }
            } else if (this.currentStep === 2) {
                if (this.isStep2Valid()) {
                    await this.submitCase(); // Await submission to ensure it's done before moving to the next step
                } else {
                    this.formError = 'missingFields';
                }
            }
        },
        previousStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
                this.formError = null;
            }
        },
        isStep1Valid() {
            return this.formData.platform && this.formData.otherAccountLinked &&
                (this.formData.otherAccountLinked !== 'yes' || this.formData.otherAccountLinkedPlatform);
        },
        getStep1Error() {
            if (!this.formData.platform || !this.formData.otherAccountLinked) {
                return 'Please fill in all the required fields.';
            }
            if (this.formData.otherAccountLinked === 'yes' && !this.formData.otherAccountLinkedPlatform) {
                return 'Please provide the platform of the linked account.';
            }
            return null;
        },
        isStep2Valid() {
            return this.formData.thirdPartySoftware !== '' && this.formData.description;
        },
        async submitCase() {
            try {
                const response = await fetch('https://api.codfalseban.com/v1/account/case/submit', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    credentials: 'include', // Include credentials (cookies)
                    body: JSON.stringify(this.formData)
                });

                const result = await response.json();

                if (result.status === 'success') {
                    this.currentStep = 3;
                    this.sessionData.hasSubmittedCase = true;
                } else {
                    this.formError = result.message; // Display error message from the server
                }
            } catch (error) {
                this.formError = 'Failed to submit the case. Please try again later.';
            }
        }
    }
};
</script>

<style scoped>
.progress-container {
    display: flex;
    align-items: center;
}

.progress-step {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #333;
}

.progress-step.active {
    background-color: #007bff;
    color: #fff;
}

.progress-bar {
    flex-grow: 1;
    height: 5px;
    background-color: #e0e0e0;
    margin: 0 10px;
}

.progress-bar.active {
    background-color: #007bff;
}

.card-title {
    font-weight: bold;
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
}

.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
}

.icon-md {
    width: 2.6rem;
    height: 2.6rem;
    line-height: 2.6rem;
    text-align: center;
    font-size: 0.8rem;
}

.bg-approach {
    --bs-bg-opacity: 1;
    background-color: rgb(9 21 184 / 14%) !important;
}
</style>
