<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">About us</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Our mission to support Call of Duty players facing false bans.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5" style="margin-top: -97px;">
                <div class="row justify-content-center">



                </div>
            </div>
        </section>

    </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
    components: {
        AppHeader
    }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>