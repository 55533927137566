<template>
    <div>
        <header class="py-5">
            <div class="container px-6 pb-5">
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Finalize</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Confirm and add required info to activate your account.
                    </p>
                </div>
            </div>
        </header>

        <section class="bg-body py-5">
            <div class="container mb-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="progress-container">
                            <div :class="['progress-step', { active: currentStep >= 1 }]">1</div>
                            <div :class="['progress-bar', { active: currentStep >= 2 }]"></div>
                            <div :class="['progress-step', { active: currentStep >= 2 }]" ref="step2">2</div>
                            <div :class="['progress-bar', { active: currentStep >= 3 }]"></div>
                            <div :class="['progress-step', { active: currentStep >= 3 }]">3</div>
                        </div>
                    </div>
                </div>

                <div class="mt-5 d-flex justify-content-center align-items-center">
                    <div class="col-12 col-md-6">
                        <div v-if="currentStep === 1">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Step 1</h4>
                                    <h6 class="card-subtitle mb-2 text-muted">Details regarding your ban</h6>

                                    <p class="card-text">
                                        To effectively assist you in addressing a false ban, we require
                                        information surrounding the ban if you want us to formally
                                        challenge the ban on your behalf.
                                    </p>
                                    <form class="mt-4">
                                        <div class="mb-3">
                                            <label for="activisionId" class="form-label fw-bold">Activision ID</label>
                                            <input type="text" class="form-control" id="activisionId"
                                                placeholder="Enter your Activision ID" v-model="formData.activisionId"
                                                required>
                                        </div>

                                        <div class="mb-3">
                                            <label class="form-label fw-bold">Duration</label>
                                            <div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="banType"
                                                        id="permanentBan" value="permanent" v-model="formData.banType"
                                                        required>
                                                    <label class="form-check-label" for="permanentBan">
                                                        Permanent
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="banType"
                                                        id="temporaryBan" value="temporary" v-model="formData.banType"
                                                        required>
                                                    <label class="form-check-label" for="temporaryBan">
                                                        Temporary
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <label for="dateOfBan" class="form-label fw-bold">Date of Ban</label>
                                            <input type="date" class="form-control" id="dateOfBan"
                                                v-model="formData.dateOfBan" required>
                                        </div>

                                        <div v-if="formError" class="alert alert-danger" role="alert">
                                            <b><i class="fa-solid fa-circle-exclamation"></i> The following error has
                                                occurred:</b>
                                            <ul style="margin: 0; padding: 0 0 0 20px; list-style:none;">
                                                <li v-if="formError === 'missingFields'">Please fill in all the fields
                                                </li>
                                                <li v-if="formError === 'cutoffDate'">We only assist with bans that
                                                    occurred after October 25, 2019, as our assistance is limited to
                                                    those cases that fall under the <a style="color:white;"
                                                        href="https://support.activision.com/articles/call-of-duty-security-and-enforcement-policy"
                                                        target="_blank">Security and Enforcement</a> scope.</li>
                                                <li v-if="formError === 'futureDate'">The date of the ban cannot be in
                                                    the future. Please provide a valid past date.</li>
                                            </ul>
                                        </div>

                                        <button type="button" class="btn btn-success" @click="nextStep"><i
                                                class="fa-solid fa-arrow-right-to-bracket"></i> Next step</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div v-if="currentStep === 2">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Step 2</h4>
                                    <h6 class="card-subtitle mb-2 text-muted">Additional information</h6>

                                    <p class="card-text">
                                        We need some more information regarding your current ban. This includes whether
                                        you've already submitted an appeal and to confirm the reason for your ban.
                                    </p>
                                    <form class="mt-4">
                                        <div class="mb-3">
                                            <label class="form-label fw-bold">Have you already submitted an
                                                appeal?</label>
                                            <div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="priorAppeal"
                                                        id="priorAppealYes" value="yes" v-model="formData.priorAppeal"
                                                        required>
                                                    <label class="form-check-label" for="priorAppealYes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="priorAppeal"
                                                        id="priorAppealNo" value="no" v-model="formData.priorAppeal"
                                                        required>
                                                    <label class="form-check-label" for="priorAppealNo">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <label class="form-label fw-bold">Were you banned for anything other than unauthorized
                                                software/manipulation of game data?</label>
                                            <div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio"
                                                        name="banReasonSoftware" id="banReasonSoftwareYes" value="yes"
                                                        v-model="formData.banReasonSoftware" required>
                                                    <label class="form-check-label" for="banReasonSoftwareYes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio"
                                                        name="banReasonSoftware" id="banReasonSoftwareNo" value="no"
                                                        v-model="formData.banReasonSoftware" required>
                                                    <label class="form-check-label" for="banReasonSoftwareNo">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <label class="form-label fw-bold">Have you actually performed actions that
                                                could be
                                                classified as such?</label>
                                            <div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="performedActions"
                                                        id="performedActionsYes" value="yes"
                                                        v-model="formData.performedActions" required>
                                                    <label class="form-check-label" for="performedActionsYes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="performedActions"
                                                        id="performedActionsNo" value="no"
                                                        v-model="formData.performedActions" required>
                                                    <label class="form-check-label" for="performedActionsNo">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="formError" class="alert alert-danger" role="alert">
                                            <b><i class="fa-solid fa-circle-exclamation"></i> The following error has
                                                occurred:</b>
                                            <ul style="margin: 0; padding: 0 0 0 20px; list-style:none;">
                                                <li v-if="formError === 'missingFields'">Please fill in all the fields
                                                </li>
                                                <li v-if="formError === 'step2Invalid'">You must select "No" for both
                                                    "Were you banned for unauthorized software/manipulation of game
                                                    data?" and "Have you actually performed actions that could be
                                                    classified as such?" to proceed. We only provide assistance if you
                                                    are sure you haven't committed such actions.</li>
                                                <li v-if="formError === 'finalizeError'">
                                                    Something went wrong on our end. Please try again. If the issue
                                                    persists, contact us.
                                                </li>
                                            </ul>
                                        </div>

                                        <button type="button" style="float: left;" class="btn btn-light"
                                            @click="previousStep">
                                            <i class="fa-solid fa-arrow-left"></i> Previous step
                                        </button>
                                        <button type="submit" style="float: right;" class="btn btn-success"
                                            @click.prevent="nextStep">
                                            <i class="fa-solid fa-arrow-right-to-bracket"></i> Next step
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div v-if="currentStep === 3">
                            <div class="alert alert-dismissible alert-success">
                                <strong><i class="fa-solid fa-circle-check"></i> That's all the information we
                                    needed.</strong>
                                <br>
                                You are done with the finalization of your account. You can now
                                <router-link to="/account/overview"><span class="badge rounded-pill bg-light"
                                        style="color:black;"><i class="fa-solid fa-arrow-right-to-bracket"></i> proceed
                                        to your account</span>
                                </router-link>
                                or wait 10 seconds and we'll redirect you automatically.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import AppHeader from '@/components/app/AppHeader.vue';
import { sessionData } from '@/session';
import confetti from 'canvas-confetti';

export default {
    components: {
        AppHeader
    },
    setup() {
        return {
            sessionData,
        };
    },
    data() {
        return {
            currentStep: 1,
            confettiTriggered: false,
            formData: {
                activisionId: '',
                banType: '',
                dateOfBan: '',
                priorAppeal: '',
                banReasonSoftware: '',
                performedActions: '',
            },
            formError: null // Use null to indicate no error initially
        };
    },
    methods: {
        async nextStep() {
            if (this.currentStep === 1) {
                console.log(this.formData);
                if (!this.isStep1Valid()) {
                    this.formError = 'missingFields';
                } else {
                    const dateError = this.isDateValid();
                    if (dateError) {
                        this.formError = dateError;
                    } else {
                        this.formError = null;
                        this.currentStep++;
                    }
                }
            } else if (this.currentStep === 2) {
                if (!this.isStep2Valid()) {
                    this.formError = 'missingFields';
                } else if (this.isStep2Invalid()) {
                    this.formError = 'step2Invalid';
                } else {
                    this.formError = null;
                    console.log(this.sessionData.userId);
                    // Call finalizeRegistration and only proceed if it is successful
                    const success = await this.finalizeRegistration();
                    if (success) {
                        this.currentStep++;
                        if (this.currentStep === 3 && !this.confettiTriggered) {
                            // Function to handle smooth scrolling
                            const smoothScrollToTop = () => {
                                return new Promise((resolve) => {
                                    const scrollElement = document.scrollingElement || document.documentElement;
                                    const start = scrollElement.scrollTop;
                                    const scrollDuration = 250; // Total time in ms

                                    const scrollStep = (timestamp, startTime) => {
                                        const elapsed = timestamp - startTime;
                                        const progress = Math.min(elapsed / scrollDuration, 1); // Ensure progress doesn't exceed 1
                                        scrollElement.scrollTop = start * (1 - progress); // Ease-out effect

                                        if (progress < 1) {
                                            requestAnimationFrame((newTimestamp) => scrollStep(newTimestamp, startTime));
                                        } else {
                                            resolve();
                                        }
                                    };

                                    requestAnimationFrame((timestamp) => scrollStep(timestamp, timestamp));
                                });
                            };


                            // Execute scrolling, then trigger confetti and update state
                            smoothScrollToTop().then(() => {
                                this.triggerConfetti();
                                this.confettiTriggered = true;
                                this.sessionData.onBoarding = true;

                                // Optional: Navigate to the new route after the confetti is triggered
                                setTimeout(() => {
                                    this.$router.push('/account/overview');
                                }, 10000);
                            });
                        }



                    }
                }
            }
        },
        previousStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
                this.formError = null; // Clear any existing error when going back
            }
        },
        triggerConfetti() {
            this.$nextTick(() => {
                const step2Element = this.$refs.step2;
                if (step2Element) {
                    const rect = step2Element.getBoundingClientRect();
                    confetti({
                        particleCount: 100,
                        spread: 70,
                        origin: {
                            x: (rect.left + rect.right) / 2 / window.innerWidth,
                            y: rect.top / window.innerHeight
                        }
                    });
                }
            });
        },
        isStep1Valid() {
            return this.formData.activisionId && this.formData.banType && this.formData.dateOfBan;
        },
        isStep2Valid() {
            return this.formData.priorAppeal && this.formData.banReasonSoftware && this.formData.performedActions;
        },
        isStep2Invalid() {
            return this.formData.banReasonSoftware === 'yes' || this.formData.performedActions === 'yes';
        },
        isDateValid() {
            const cutoffDate = new Date('2019-10-25');
            const selectedDate = new Date(this.formData.dateOfBan);
            const currentDate = new Date();

            if (this.formData.dateOfBan) {
                if (selectedDate <= cutoffDate) {
                    return 'cutoffDate'; // Date is too old
                }
                if (selectedDate > currentDate) {
                    return 'futureDate'; // Date is in the future
                }
            }
            return null; // No error
        },
        async finalizeRegistration() {
            try {
                const response = await fetch('https://api.codfalseban.com/v1/auth/user/finalize', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        user_id: this.sessionData.userId,
                        activision_id: this.formData.activisionId,
                        duration: this.formData.banType,
                        date_of_ban: this.formData.dateOfBan,
                        appeal: this.formData.priorAppeal
                    })
                });

                const result = await response.json();

                if (result.status === 'success') {
                    return true; // Indicate success
                } else {
                    this.formError = 'finalizeError'; // Display error message from API
                    return false; // Indicate failure
                }
            } catch (error) {
                this.formError = 'finalizeError';
                return false; // Indicate failure
            }
        }
    },
    watch: {
        currentStep(newStep) {
            if (newStep === 3 && !this.confettiTriggered) {
                this.triggerConfetti();
                this.confettiTriggered = true;
            }
        }
    }
};
</script>

<style scoped>
.progress-container {
    display: flex;
    align-items: center;
}

.progress-step {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #333;
}

.progress-step.active {
    background-color: #007bff;
    color: #fff;
}

.progress-bar {
    flex-grow: 1;
    height: 5px;
    background-color: #e0e0e0;
    margin: 0 10px;
}

.progress-bar.active {
    background-color: #007bff;
}
</style>
