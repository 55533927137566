import { createRouter, createWebHistory } from 'vue-router';
import { sessionData } from '../session.js';
import HomePage from '../views/home/HomePage.vue';
import ApproachPage from '@/views/approach/ApproachPage.vue';
import GuidelinesPage from '@/views/guidelines/GuidelinesPage.vue';
import AboutUsPage from '@/views/about-us/AboutUsPage.vue';
import ContactPage from '@/views/contact/ContactPage.vue';
import VoorwaardenPage from '@/views/policies/VoorwaardenPage.vue';
import PrivacyPage from '@/views/policies/PrivacyPage.vue';
import RequestHelpPage from '@/views/request-help/RequestHelpPage.vue';

// Account authentication
import LoginPage from '@/views/account/auth/LoginPage.vue';
import RegisterPage from '@/views/account/auth/RegisterPage.vue';

// Account panel
import AccountOverview from '@/views/account/overview/AccountOverview.vue';
import AccountCase from '@/views/account/case/AccountCase.vue';
import AccountCaseSubmit from '@/views/account/case/submit/AccountCaseSubmit.vue';
import AccountPreferences from '@/views/account/preferences/AccountPreferences.vue';
import AccountSupport from '@/views/account/support/AccountSupport.vue';
import AccountFinalize from '@/views/account/finalize/AccountFinalize.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { title: 'We fight your false ban — Cal of Duty False Bans' }
  },
  {
    path: '/approach',
    name: 'Approach',
    component: ApproachPage,
    meta: { title: 'Our approach — Cal of Duty False Bans' }
  },
  {
    path: '/guidelines',
    name: 'Guidelines',
    component: GuidelinesPage,
    meta: { title: 'Guidelines — Cal of Duty False Bans' }
  },
  {
    path: '/about-us',
    name: 'About us',
    component: AboutUsPage,
    meta: { title: 'About us — Cal of Duty False Bans' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage,
    meta: { title: 'Contact — Cal of Duty False Bans' }
  },
  {
    path: '/tos',
    name: 'Terms of Service',
    component: VoorwaardenPage,
    meta: { title: 'Terms of Service — Cal of Duty False Bans' }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPage,
    meta: { title: 'Privacy — Cal of Duty False Bans' }
  },
  {
    path: '/request-help',
    name: 'Request help',
    component: RequestHelpPage,
    meta: { title: 'Request help — Cal of Duty False Bans' }
  },
  {
    path: '/account/auth/login',
    name: 'Login',
    component: LoginPage,
    meta: { title: 'Login — Cal of Duty False Bans' },
    beforeEnter: async (to, from, next) => {
      try {
        const response = await fetch('https://api.codfalseban.com/v1/auth/user/verify', {
          method: 'GET',
          credentials: 'include'
        });

        if (response.ok && response.status === 200) { // Check both response status and code
          const userData = await response.json();
          if (userData.status === 'success') {
            // User is already logged in
            next('/?r=already_logged_in');
            return;
          }
        }
      } catch (error) {
        console.error('Error checking session status:', error);
      }
      next(); // Allow navigation to the login page
    }
  },
  {
    path: '/account/auth/register',
    name: 'Register',
    component: RegisterPage,
    meta: { title: 'Register — Cal of Duty False Bans' },
    beforeEnter: async (to, from, next) => {
      try {
        const response = await fetch('https://api.codfalseban.com/v1/auth/user/verify', {
          method: 'GET',
          credentials: 'include'
        });

        if (response.ok && response.status === 200) { // Check both response status and code
          const userData = await response.json();
          if (userData.status === 'success') {
            // User is already logged in
            next('/account/overview');
            return;
          }
        }
      } catch (error) {
        console.error('Error checking session status:', error);
      }
      next(); // Allow navigation to the login page
    }
  },
  {
    path: '/account/finalize',
    name: 'Finalize account',
    component: AccountFinalize,
    meta: { title: 'Finalize account — Call of Duty False Bans' },
    beforeEnter: (to, from, next) => {
      if (!sessionData.isLoggedIn) {
        next('/account/auth/login');  // Redirect to login if not logged in
      } else if (sessionData.onBoarding) {
        next('/account/overview');  // Redirect to overview if onboarding is already done
      } else {
        next();  // Allow access to finalize if onboarding is not done
      }
    }
  },
  {
    path: '/account/overview',
    name: 'Account overview',
    component: AccountOverview,
    meta: { title: 'Account overview — Call of Duty False Bans' },
    beforeEnter: (to, from, next) => {
      if (!sessionData.isLoggedIn) {
        next('/account/auth/login');  // Redirect to login if not logged in
      } else if (!sessionData.onBoarding) {
        next('/account/finalize');  // Redirect to finalize if onboarding is not done
      } else {
        next();  // Allow access to overview if onboarding is done
      }
    }
  },
  {
    path: '/account/case',
    name: 'Account case',
    component: AccountCase,
    meta: { title: 'Your case — Cal of Duty False Bans' },
    beforeEnter: (to, from, next) => {
      if (!sessionData.isLoggedIn) {
        next('/account/auth/login');  // Redirect to login if not logged in
      } else if (!sessionData.onBoarding) {
        next('/account/finalize');  // Redirect to finalize if onboarding is not done
      } else {
        next();  // Allow access to case if onboarding is done
      }
    }
  },
  {
    path: '/account/case/submit',
    name: 'Submit case',
    component: AccountCaseSubmit,
    meta: { title: 'Submit case — Cal of Duty False Bans' },
    beforeEnter: (to, from, next) => {
      if (!sessionData.isLoggedIn) {
        next('/account/auth/login');  // Redirect to login if not logged in
      } else if (!sessionData.onBoarding) {
        next('/account/finalize');  // Redirect to finalize if onboarding is not done
      } else {
        next();  // Allow access to case if onboarding is done
      }
    }
  },
  {
    path: '/account/preferences',
    name: 'Account preferences',
    component: AccountPreferences,
    meta: { title: 'Preferences — Cal of Duty False Bans' },
    beforeEnter: (to, from, next) => {
      if (!sessionData.isLoggedIn) {
        next('/account/auth/login');  // Redirect to login if not logged in
      } else if (!sessionData.onBoarding) {
        next('/account/finalize');  // Redirect to finalize if onboarding is not done
      } else {
        next();  // Allow access to preferences if onboarding is done
      }
    }
  },
  {
    path: '/account/support',
    name: 'Account support',
    component: AccountSupport,
    meta: { title: 'Support — Cal of Duty False Bans' },
    beforeEnter: (to, from, next) => {
      if (!sessionData.isLoggedIn) {
        next('/account/auth/login');  // Redirect to login if not logged in
      } else if (!sessionData.onBoarding) {
        next('/account/finalize');  // Redirect to finalize if onboarding is not done
      } else {
        next();  // Allow access to support if onboarding is done
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve({ top: 0, behavior: 'smooth' });
        }, 0);
      });
    }
  }
});

// Global navigation guard to set document title based on route meta
router.beforeEach((to, from, next) => {
  const title = to.meta.title || '@codfalseban.com/web';
  document.title = title;
  next();
});

// Global guard to ensure finalize is not accessible after onboarding
router.beforeEach((to, from, next) => {
  if (sessionData.onBoarding && to.path === '/account/finalize') {
    next('/account/overview');
  } else {
    next();
  }
});

export default router;
