// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { sessionData } from './session'; // Import sessionData from session.js
import { sessionStatus } from './sessionStatus'; // Import sessionStatus
import NProgress from 'nprogress'; // Import NProgress
import 'nprogress/nprogress.css'; // Import NProgress CSS
import './assets/styles.css'; // Import your global stylesheet with custom NProgress styles


async function initializeApp() {
    try {
        // Check for an active session
        const response = await fetch('https://api.codfalseban.com/v1/auth/user/verify', {
            method: 'GET',
            credentials: 'include',
        });

        if (response.ok) {
            const data = await response.json();
            if (data.status === 'success') {
                sessionData.isLoggedIn = true;
                sessionData.fullName = `${data.firstName} ${data.surname}`;
                sessionData.firstName = data.firstName;
                sessionData.onBoarding = data.onboarding
            }
        }

        // Set the initial verification flag to true
        sessionStatus.initialVerificationDone = true;

        const app = createApp(App);
        app.provide('session', sessionData);
        app.use(router);

        // Add navigation guards for NProgress
        router.beforeEach((to, from, next) => {
            NProgress.start(); // Start the progress bar
            next();
        });

        router.afterEach(() => {
            NProgress.done(); // Finish the progress bar
        });

        app.mount('#app');
    } catch (error) {
        console.error('Error checking session status:', error);
    }
}

initializeApp();
