<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Terms of Service</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Understand your obligations and rights while using our services.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5" style="margin-top: -97px;">
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <div class="card">
                            <div class="card-body">
                                <h3 style="font-weight: bold;">1. Service Description</h3>
                                <p>1.1 This service is provided by CoD False Ban (“we”, “us”, or
                                    “our”) to assist individuals (“Clients”, “you”, or “your”) who believe they have
                                    been wrongfully or unfairly banned from the Call of Duty video game (“the Game”).
                                </p>
                                <p>1.2 We leverage applicable regulations, including the General Data
                                    Protection Regulation (GDPR) and the Unfair Commercial Practices Directive (UCPD),
                                    where relevant, to challenge such bans on your behalf.</p>
                                <p>1.3 Our service is limited to individuals living in the European Union (EU)
                                    due to the specific legal frameworks we employ to contest bans which are primarily applicable
                                     within the EU jurisdiction.
                                </p>
                                <p>1.4 Our service includes, but is not limited to:</p>
                                <ul>
                                    <li>Guidance and support throughout the unban process</li>
                                    <li>Development of personalized case strategies</li>
                                    <li>Case management and progress tracking</li>
                                    <li>Communication with Activision (the Game's publisher) and other relevant parties
                                    </li>
                                    <li>Preparation and submission of appeals and other relevant documentation</li>
                                </ul>

                                <h3 style="font-weight: bold;">2. Client Responsibilities</h3>
                                <p>2.1 You must provide accurate and complete information about your ban, including any
                                    relevant communication with Activision or other parties.</p>
                                <p>2.2 You agree to cooperate fully with us throughout the process, including providing
                                    any requested documentation, information, or evidence.</p>
                                <p>2.3 You understand that we cannot guarantee a successful outcome in challenging your
                                    ban. We will, however, make every reasonable effort to assist you based on the
                                    information and evidence provided.</p>
                                <p>2.4 You are responsible for any actions taken by Activision or other third parties in
                                    relation to your ban, regardless of our involvement.</p>

                                <h3 style="font-weight: bold;">3. Our Responsibilities</h3>
                                <p>3.1 We will handle your case with professionalism, confidentiality, and due
                                    diligence.</p>
                                <p>3.2 We will develop a personalized strategy tailored to your specific circumstances
                                    and the nature of your ban.</p>
                                <p>3.3 We will keep you informed of the progress of your case through regular updates
                                    and access to our case management system.</p>
                                <p>3.4 We will communicate with Activision and other relevant parties on your behalf,
                                    advocating for your position and seeking a resolution to your ban.</p>

                                <h3 style="font-weight: bold;">4. Fees and Payment</h3>
                                <p>4.1 Our fees cover the administrative and operational costs associated with handling
                                    and submitting your case. This may include, but is not limited to, postage,
                                    printing, and materials.</p>
                                <p>4.2 You agree to pay the fees as outlined on our website or as otherwise communicated
                                    to you.</p>
                                <p>4.3 Payment is due upon initiation of our services.</p>
                                <p>4.4 We reserve the right to adjust our fees at any time, with reasonable notice
                                    provided to existing clients.</p>

                                <h3 style="font-weight: bold;">5. Termination</h3>
                                <p>5.1 Either party may terminate this agreement at any time, with or without cause.</p>
                                <p>5.2 In the event of termination by you, we will refund any unused portion of your
                                    fees, less any costs already incurred on your behalf.</p>
                                <p>5.3 In the event of termination by us, we will provide a full refund of any unused
                                    fees.</p>

                                <h3 style="font-weight: bold;">6. Limitation of Liability</h3>
                                <p>6.1 We are not liable for any damages, losses, or expenses arising from the use of
                                    our service, including but not limited to any actions taken by Activision or other
                                    third parties in relation to your ban.</p>
                                <p>6.2 Our total liability to you, for any reason and under any cause of action, will
                                    not exceed the amount of fees paid by you for our services.</p>

                                <h3 style="font-weight: bold;">7. Acceptance</h3>
                                <p>8.1 By using our service, you acknowledge that you have read, understood, and agree
                                    to be bound by these terms of service.</p>

                                <h3 style="font-weight: bold;">8. Modifications</h3>
                                <p>9.1 We reserve the right to modify these terms of service at any time. Any changes
                                    will be effective immediately upon posting on our website. Your continued use of our
                                    service after any such changes constitutes your acceptance of the new terms of
                                    service.</p>

                                <span style="float:right; font-size:12px;">Last update: 31-07-2023</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>
</template>
<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
    components: {
        AppHeader
    },
    data() {
        // your data here
    }
}
</script>


<style scoped>
/* Add any component-specific styles here */
</style>