<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <router-link to="/">
                </router-link>
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Privacy</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        How we process your data.
                    </p>
                </div>
            </div>
        </header>

        <section class="bg-body py-5">
            <div class="container mb-5" style="margin-top: -97px;">
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <div class="card">
                            <div class="card-body">
                                <h3 style="font-weight: bold;">1. Introduction</h3>
                                <p>1.1 CoD False Ban ("we", "us", or "our") respects the privacy of our clients and
                                    website visitors.
                                    This Privacy Policy outlines how we handle personal data collected through our
                                    services and website.</p>

                                <h3 style="font-weight: bold;">2. Information We Collect</h3>
                                <p>2.1 We collect and process personal data when you use our services, visit our
                                    website, or otherwise
                                    interact with us. This may include:</p>
                                <ul>
                                    <li>Personal information such as name, email address, and gaming account details.
                                    </li>
                                    <li>Communications data including correspondence with Activision or other relevant
                                        parties.</li>
                                    <li>Usage data such as IP addresses and other statistics.</li>
                                </ul>

                                <h3 style="font-weight: bold;">3. How We Use Your Information</h3>
                                <p>3.1 We use your personal information for the following purposes:</p>
                                <ul>
                                    <li>To provide our services, including challenging your ban and managing your case.
                                    </li>
                                    <li>To communicate with you regarding the progress of your case and our services.
                                    </li>
                                    <li>To comply with legal obligations, including data protection laws.</li>
                                    <li>To improve our services using aggregated, non-identifiable data.</li>
                                </ul>

                                <h3 style="font-weight: bold;">4. Information Sharing and Disclosure</h3>
                                <p>4.1 We do not share your personal information with third parties, except in the
                                    following cases:</p>
                                <ul>
                                    <li>With your consent, or as necessary to perform our services (e.g., sharing
                                        information with Activision).</li>
                                    <li>If required by law or in response to valid requests by public authorities.</li>
                                    <li>With third-party service providers who assist in our operations, bound by
                                        confidentiality obligations.</li>
                                </ul>

                                <h3 style="font-weight: bold;">5. Data Security</h3>
                                <p>5.1 We take appropriate technical and organizational measures to protect your
                                    personal information
                                    from unauthorized access, loss, or misuse. However, no method of transmission over
                                    the internet
                                    or electronic storage is 100% secure.</p>

                                <h3 style="font-weight: bold;">6. Data Retention</h3>
                                <p>6.1 We retain your personal information only as long as necessary for the purposes
                                    for which it was
                                    collected or as required by law.</p>

                                <h3 style="font-weight: bold;">7. Your Rights</h3>
                                <p>7.1 You have the right to access, correct, or delete your personal information. You
                                    may also object
                                    to or request the restriction of our processing of your data in certain
                                    circumstances.</p>
                                <p>7.2 To exercise these rights, please contact us using the contact information
                                    provided in section 9 of this policy.</p>

                                <h3 style="font-weight: bold;">8. Changes to This Privacy Policy</h3>
                                <p>8.1 We may update this Privacy Policy from time to time. We will post any
                                    changes on our website
                                    and notify you where necessary.</p>

                                <h3 style="font-weight: bold;">9. Contact Information</h3>
                                <p>9.1 For questions about this Privacy Policy or the processing of your personal data,
                                    you can contact us at:</p>
                                <ul>
                                    <li><strong>Email</strong>: <a
                                            href="mailto:info@codfalseban.com">info@codfalseban.com</a></li>
                                </ul>
                                <span style="float:right; font-size:12px;">Last updated: 31-07-2023</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
    components: {
        AppHeader
    },
    data() {
        // your data here
    }
}
</script>


<style scoped>
/* Add any component-specific styles here */
</style>