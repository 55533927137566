<template>
    <!-- Footer -->
    <footer class="bg-light py-4 mt-auto">
        <div class="container px-5">
            <div class="row align-items-center justify-content-between flex-column flex-sm-row">
                <div class="col-md-3">
                    <div class="small m-0">
                        <img src="https://cdn.codfalseban.com/assets/images/icon2.svg" width="35" alt="Logo" />
                        <span
                            style="margin-left: 4px; font-weight: 700; font-size: 18px; color: rgb(5, 4, 25); font-family: Montserrat Alternates, sans-serif;">
                            False Ban
                        </span>
                        <p>
                            We are dedicated to helping players who have been wrongfully banned from Call of Duty regain
                            access to their accounts.
                            We leverage specific legal frameworks to challenge unfair bans.
                        </p>
                    </div>
                </div>
                <div class="col-auto">
                    <router-link class="small" to="/tos">Terms of Service</router-link>
                    <span class="mx-1">&middot;</span>
                    <router-link class="small" to="/privacy">Privacy Policy</router-link>
                    <span class="mx-1">&middot;</span>
                    <router-link class="small" to="/contact">Contact</router-link>
                </div>
                <hr />
                <div class="col-auto">
                    <div class="small m-0">
                        © 2024 CoD False Bans. Made with <span style="color:red;">&#9829;</span> for all players who have been falsely banned.<br />
                        <span style="font-size: 12px">Call of Duty (CoD) False Bans is not affiliated with or endorsed
                            by
                            <a href="https://www.activision.com/" class="fw-light" target="_blank">Activision
                                Publishing, Inc.</a> or the Call of Duty franchise.
                            "Call of Duty" is a registered trademark of
                            <a href="https://www.activision.com/" class="fw-light" target="_blank">Activision
                                Publishing, Inc.</a>
                            All trademarks belong to their respective owners. Use of these names does not imply any
                            affiliation or endorsement.</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
// No JavaScript needed for static footer content
export default {
    name: 'AppFooter'
}
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>
