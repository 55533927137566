<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Preferences</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Here you will find an overview of your personal information and preferences.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5">
                <div class="row justify-content-center">
                    <div class="col-lg-3 mb-4">
                        <!-- Include Account Navigation -->
                        <AccountNavigation :currentPath="currentPath" />
                    </div>
                    <div class="col-lg-7">

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';
import AccountNavigation from '@/components/account/nav/AccountNavigation.vue';
import { sessionData } from '@/session';

export default {
    components: {
        AppHeader,
        AccountNavigation
    },
    setup() {
        const currentPath = 'account/persoonlijke-gegevens'; // Set the current path for the navigation

        return {
            sessionData,
            currentPath
        };
    }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
